<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">
        <p>Sửa phiếu đăng ký học tập</p>
      </div>
    </div>
    <div class="card-body">
      <RegisterStudyFormPartner
          :isShow="true"
          :contract_prop="contract"
          :verify="verify">
      </RegisterStudyFormPartner>
    </div>
    <div class="card-header d-flex justify-content-end">
      <button :disabled="is_loading_submit" class="btn btn-success" @click="updateContract">
        <i v-if="is_loading_submit" class="el-icon-loading"></i>Cập nhật
      </button>
    </div>
  </div>
</template>

<script>

import {CONTRACT_ONLINE_GET_BY_ID, CONTRACT_ONLINE_UPDATE} from "@/core/services/store/contract/contractOnline.module";
import RegisterStudyFormPartner from "@/views/pages/customer/components/RegisterStudyFormPartner";

export default {
  name: "EditContractPartner",
  components: {RegisterStudyFormPartner},
  data() {
    return {
      is_loading_submit: false,
      contract: {
        is_older: true,
        tuition_payment_id: '',
        name: '',
        phone: '',
        phone_other: '',
        email: '',
        birthday: '',
        center_id: '',
        branch_id: '',
        total: '',
        courses: [],
        parent: {
          type: 'bo',
          other_value: '',
          name: '',
          phone: '',
          phone_other: '',
        }
      },
      verify: {
        otp: '',
        loading_send: true,
        loading_verify: true
      },
    }
  },
  mounted() {
    this.getById();
  },
  methods: {
    updateContract() {
      this.is_loading_submit = true;
      this.contract.confirm = 1;
      this.contract.status = 1;
      this.contract.is_older = true;
      this.$store.dispatch(CONTRACT_ONLINE_UPDATE, this.contract).then((data) => {
        this.$message.success(data.message);
        this.contract.id = data.data[0].id;
        this.contract.uuid = data.data[0].uuid;
        this.contract.uuid_secret = data.data[0].uuid_secret;
        this.$router.push({
          name: "contract-index"
        })
      }).catch((e) => {
        this.$message.error(e.data.message);
        if (e.data.data.message_validate_form) {
          this.errorsForm = e.data.data.message_validate_form;
        }
      }).finally(() => {
        this.is_loading_submit = false;
      })
    },
    getById() {
      let id = this.$route.params.contract_id;
      this.$store.dispatch(CONTRACT_ONLINE_GET_BY_ID, id).then((data) => {
        this.contract = data.data;
      })
    }
  }
}
</script>

<style scoped>

</style>